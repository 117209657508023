import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { toFormattedTextWithList } from '../../services/formatting';
import { colors, mobileThresholdPixels, fontSizes }
  from '../home/v2/styledComponents';

const Container = styled.div`
  color: ${colors.navy};
  box-shadow: -1px 13px 20px #F0F2F4;
  padding: 50px;

  @media (max-width: ${mobileThresholdPixels}) {
    box-shadow: none;
    padding: 30px;
  }
`;

const Content = styled.div``;

const Title2 = styled.h2`
  font-size: ${fontSizes.l};
  font-family: Libre Baskerville;
`;

const Text = styled.div`
  font-family: Roboto;
  font-size: 18px;
  line-height: 30px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

const LaRubriqueListTextContent = ({ content }) => (
  <Container>
    <Content>
      {content.title1 && <Title2>{content.title1}</Title2>}
      <Text>{toFormattedTextWithList(content.paragraph1, [])}</Text>
    </Content>
  </Container>
);

LaRubriqueListTextContent.propTypes = {
  content: PropTypes.shape({
    title1: PropTypes.string.isRequired,
    paragraph1: PropTypes.string.isRequired,
  }).isRequired,
};

export default LaRubriqueListTextContent;
