import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { toFormattedLinkedText } from '../../services/formatting';
import { colors, mobileThresholdPixels, fontSizes }
  from '../home/v2/styledComponents';

const Container = styled.div`
  color: ${colors.navy};
  box-shadow: -1px 13px 20px #F0F2F4;
  padding: 50px;

  @media (max-width: ${mobileThresholdPixels}) {
    box-shadow: none;
    padding: 30px;
  }
`;

const Content = styled.div``;

const Title2 = styled.h2`
  font-size: ${fontSizes.l};
  font-family: Libre Baskerville;
`;

const P = styled.p`
  font-family: Roboto;
  font-size: 18px;
  line-height: 30px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

const LaRubriqueClothTextContent = ({ content }) => {
  if (!content.title1) return (<div />);
  return (
    <Container>
      <Content>
        <Title2>{content.title1}</Title2>
        <P>{toFormattedLinkedText(content.paragraph1, content.links1)}</P>
        <Title2>{content.title2}</Title2>
        <P>{toFormattedLinkedText(content.paragraph2, content.links2)}</P>
      </Content>
    </Container>
  );
};

LaRubriqueClothTextContent.propTypes = {
  content: PropTypes.shape({
    title1: PropTypes.string.isRequired,
    paragraph1: PropTypes.string.isRequired,
    links1: PropTypes.arrayOf(PropTypes.string),
    title2: PropTypes.string.isRequired,
    paragraph2: PropTypes.string.isRequired,
    links2: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default LaRubriqueClothTextContent;
