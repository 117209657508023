import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import List from './List';
import LaRubriqueClothTextContent from './LaRubriqueClothTextContent';
import LaRubriqueListTextContent from './LaRubriqueListTextContent';
import CategoriesSelector from './CategoriesSelector';
import { margins, contentWidth, mobileThresholdPixels }
  from '../../tilli-components/src/components/styledComponents';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${contentWidth - 200}px;
  margin-bottom: -${margins.m};
  ${prop => prop.withMarginBottom && 'margin-bottom: 56px'};

  @media (max-width: ${mobileThresholdPixels}) {
    width: unset;
    align-items: center;
  }
`;

const LaRubriqueListContent = ({
  categories, category, subCategory, posts, context: { isMobile },
}) => {
  if (isMobile) {
    return (
      <Column>
        <List posts={posts} />
        {subCategory && <LaRubriqueClothTextContent content={subCategory.text} />}
        <CategoriesSelector
          categories={categories}
          selectedCategorySlug={category && category.slug}
          selectedSubCategorySlug={subCategory && subCategory.slug}
        />
        {(category && category.text && category.text.paragraph1) && (
          <LaRubriqueListTextContent content={category.text} />
        )}
      </Column>
    );
  }
  return (
    <Row>
      <CategoriesSelector
        categories={categories}
        selectedCategorySlug={category && category.slug}
        selectedSubCategorySlug={subCategory && subCategory.slug}
      />
      <Column>
        <Column withMarginBottom={subCategory && posts.length !== 0}>
          <List posts={posts} />
          {(category && category.text && category.text.paragraph1) && (
            <LaRubriqueListTextContent content={category.text} />
          )}
        </Column>
        {subCategory && <LaRubriqueClothTextContent content={subCategory.text} />}
      </Column>
    </Row>
  );
};

LaRubriqueListContent.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  category: PropTypes.shape({}),
  subCategory: PropTypes.shape({}),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

LaRubriqueListContent.defaultProps = {
  categories: [],
  category: null,
  subCategory: null,
};

export default withAppContext(LaRubriqueListContent);
