/* eslint-disable react/no-array-index-key */

import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors, SpanPre, ColoredSpan, BoldSpan } from '../components/home/v2/styledComponents';

const SubtitleSpan = styled.span`
  font-size: 20px;
  font-family: Libre Baskerville;
  font-weight: bold;
`;

const Li = styled.li`
  margin-top: 5px;
  margin-bottom: 5px;
`;

// return 01 for 1, 12 for 12 and 34 for 134 (edge case)
// => do not use for number that can be greater than 99
export function toTwoDigits(number) {
  if (Number.isNaN(number) || number < 0) return '00';
  if (number !== Math.floor(number)) return String(number);
  return (`0${number}`).slice(-2) || '00';
}

// returns 01 for 1, 12 for 12 and 134 for 134
// returns 32.5 for 32.5
export function toTwoDigitsOrMore(number) {
  if (Number.isNaN(number) || number < 0) return '00';
  if (number >= 100) return number;
  if (number !== Math.floor(number)) return String(number);
  return (`0${number}`).slice(-2) || '00';
}

// returns 1,00 for 1, 12,00 for 12 and 134,00 for 134
// returns 32,50 for 32.5, 28,04 for 28.045615161
export function toTwoDecimalDigits(number) {
  if (Number.isNaN(number) || number < 0) return '00,00';
  const numberIt = number.toFixed(2).replace('.', ',');
  if (number >= 10) return numberIt;
  return (`0${numberIt}`) || '00,00';
}

// returns
//    Hello,
//    world
// for Hello, \nworld
export function slashNtoBr(text, nbsp = false) {
  const splitText = text.split('\n');
  if (nbsp) {
    return splitText.map((line, index) =>
      <SpanPre key={`${line}${index}`}>{line}{splitText.length - 1 !== index && <br />}</SpanPre>);
  }
  return splitText.map((line, index) =>
    <span key={`${line}${index}`}>{line}{splitText.length - 1 !== index && <br />}</span>);
}

// return « Hello, world » for Hello, world
export function toWrappedText(text) {
  return <span>{'« '}{text}{' »'}</span>;
}

// returns
//    Hello,
//    world
// for Hello, \nworld
// return if wrap ===true «Hello, world»
export function toFormattedText(text, wrap = false, nbsp = false) {
  if (!wrap) return slashNtoBr(text, nbsp);
  return toWrappedText(slashNtoBr(text, nbsp));
}

function toFormattedList(text) {
  const splitText = text.split('<li>');
  return (
    <ul>
      {splitText.map(item => !!item && <Li key={item}>{item}</Li>)}
    </ul>
  );
}

function toFormattedSubtitleText(text) {
  return <SubtitleSpan>{text}</SubtitleSpan>;
}

function LinkOrA(link, linkStyle, subtext) {
  const style = { color: colors.navy, ...linkStyle };
  if (link && link.includes('http')) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={link} style={style}>
        {toFormattedText(subtext)}
      </a>
    );
  } else if (link && !link.includes('http')) {
    return (
      <Link to={link} style={style}>
        {toFormattedText(subtext)}
      </Link>
    );
  }
  return null;
}

export function toFormattedLinkedText(text, links = [], linkStyle = {}, hasToCopyLinks = true) {
  const copiedLinks = (hasToCopyLinks ? links && links.slice() : links) || []; // WTF x2
  return text.split('<link>').map((subtext, index) => (
    <span key={`${subtext}${index}`}>
      {(index % 2 === 1)
        ? (
          LinkOrA(copiedLinks.shift(), linkStyle, subtext)
        ) : (
          subtext.split('<subtitle>').map((subtext2, index2) => (
            <span key={`${subtext2}${index2}`}>
              {(index2 % 2 === 1)
                ? toFormattedSubtitleText(subtext2)
                : toFormattedText(subtext2)
              }
            </span>
          ))
        )
      }
    </span>
  ));
}

export function toFormattedTextWithList(text, links = undefined, linkStyle = {}) {
  const splitText = text.split('<ul>').filter(line => line !== '');
  return splitText.map((line, index) => {
    if (!links) {
      return (
        <span key={`${line}${index}`}>
          {index % 2 !== 0 ? toFormattedList(line) : toFormattedText(line)}
        </span>
      );
    }
    return (
      <span key={`${line}${index}`}>
        {index % 2 !== 0 ? toFormattedList(line) : toFormattedLinkedText(line, links, linkStyle, false)}
      </span>
    );
  });
}

// returns
//    Hello world (white)
// for <white>Hello world<white>
// returns
//    Hello world (yellow)
// for <yellow>Hello world<yellow>
export function toFormattedColoredText(text, color = 'white') {
  const splitText = text.split(`<${color}>`).filter(line => line !== '');
  if (text.indexOf(`<${color}>`) === 0) {
    return splitText.map((line, index) =>
      <ColoredSpan key={`${line}${index}`} color={index % 2 === 0 ? color : ''}>
        {toFormattedText(line, false, true)}
      </ColoredSpan>);
  }
  return splitText.map((line, index) =>
    <ColoredSpan key={`${line}${index}`} color={index % 2 !== 0 ? color : ''}>
      {toFormattedText(line, false, true)}
    </ColoredSpan>);
}

// returns by default
//    Hello world (font-weight === 500)
// for <bold>Hello world<bold>
export function toFormattedBoldText(text, fontWeight = 500, links = undefined, linkStyle = {}) {
  const splitText = text.split('<bold>').filter(line => line !== '');
  const copyLinks = links && links.slice();
  if (text.indexOf('<bold>') === 0) {
    return splitText.map((line, index) =>
      <BoldSpan key={`${line}${index}`} fontWeight={index % 2 === 0 ? fontWeight : 400}>
        {toFormattedTextWithList(line, copyLinks, linkStyle)}
      </BoldSpan>);
  }
  return splitText.map((line, index) =>
    <BoldSpan key={`${line}${index}`} fontWeight={index % 2 !== 0 ? fontWeight : 400}>
      {toFormattedTextWithList(line, copyLinks, linkStyle)}
    </BoldSpan>);
}

// return
// Commande for 1. Commande
// Commande for 121.Commande
// mmande   for Co.mmande
// Commande for Commande
export function toUnNumberedText(text) {
  const start = text.indexOf('.') + 1;
  if (start === 0) return text;
  return text.slice(start).trimStart();
}
