import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { colors, margins, mobileThresholdPixels } from '../home/v2/styledComponents';

import triangle from '../../assets/laRubrique/triangle.svg';
import sparkler from '../../assets/laRubrique/sparkler.png';

const Container = styled.div`
  width: 200px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 125px;
    margin-top: ${margins.l};
  }
`;

const SubContainer = styled.div`
  @media (max-width: ${mobileThresholdPixels}) {
    width: 180px;
  }
`;

const Sparkler = styled.img`
  position: absolute;
  left: ${prop => prop.closer ? '-20px' : '-50px'};
  top: -5px;
  height: 30px;
  width: 30px;
`;

const Triangle = styled.img`
  position: absolute;
  cursor: pointer;
  transform: rotate(-90deg);
  ${prop => prop.isOpen && 'transform: rotate(0deg)'};
  left: -15px;
  top: 6px;
  width: 9px;
`;

const LinkContainer = styled.div`
  position: relative;
  margin-bottom: ${margins.s};
`;

const SubLinkContainer = styled(LinkContainer)``;

const linkStyle = {
  textDecoration: 'none',
  color: `${colors.navy}`,
  fontWeight: 500,
  lineHeight: '21px',
};

const linkClothesStyle = {
  ...linkStyle,
  fontWeight: 400,
  margin: '15px 0px 0px 15px',
  display: 'block',
};

class CategoriesSelector extends React.Component {
  constructor() {
    super();
    this.state = { isOpenSelectedCategory: true };
  }

  toggleIsOpenSelectedCategory() {
    this.setState({ isOpenSelectedCategory: !this.state.isOpenSelectedCategory });
  }

  render() {
    const { categories, selectedCategorySlug, selectedSubCategorySlug } = this.props;
    const { isOpenSelectedCategory } = this.state;
    return (
      <Container>
        {categories.map(category => (
          <LinkContainer key={category.slug}>
            {(category.slug === selectedCategorySlug && !selectedSubCategorySlug) &&
              <Sparkler src={sparkler} alt={`Vos inspirations pour une ${category.name}`} />
            }
            {category.clothCategories &&
              <Triangle
                src={triangle}
                alt="Retouches, broderies et transformations sur vos vêtements"
                isOpen={isOpenSelectedCategory}
                onClick={() => this.toggleIsOpenSelectedCategory()}
              />
            }
            <SubContainer>
              <Link
                to={category.path}
                onClick={() => this.toggleIsOpenSelectedCategory()}
                style={linkStyle}
              >
                {category.name}<br />
              </Link>
              {category.clothCategories && isOpenSelectedCategory &&
                category.clothCategories.map(clothCategory =>
                  <SubLinkContainer key={clothCategory.path}>
                    {(selectedSubCategorySlug === clothCategory.slug) &&
                      <Sparkler
                        src={sparkler} alt={`Vos inspirations pour une ${category.name} de ${clothCategory.name}`}
                        closer
                      />
                    }
                    <Link
                      to={clothCategory.path}
                      style={linkClothesStyle}
                    >
                      {clothCategory.name}<br />
                    </Link>
                  </SubLinkContainer>,
                )
              }
            </SubContainer>
          </LinkContainer>
        ))}
      </Container>
    );
  }
}

CategoriesSelector.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedCategorySlug: PropTypes.string,
  selectedSubCategorySlug: PropTypes.string,
};

CategoriesSelector.defaultProps = {
  selectedCategorySlug: null,
  selectedSubCategorySlug: null,
};

export default CategoriesSelector;
