import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { colors, margins, mobileThresholdPixels } from '../styledComponents';

const Content = styled.div`
  display: flex;
  position: relative;
  width: 282px;
  height: 332px;
  background: #FFFFFF;
  margin: 0px ${margins.m} ${margins.l};
  box-shadow: -1px 13px 20px #F0F2F4;
  border-radius: 5px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 350px;
    margin: 20px auto;
    width: 350px;
  }
`;

const StyledLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  width: 100%;
`;

const Img = styled.img`
  width: 227px;
  height: 194px;
  object-fit: cover;
  cursor: pointer;
  margin: 28px 28px 22px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 200px;
    margin: 30px auto;
    width: 300px;
  }
`;

const Overlay = styled.div`
  background-color: white;
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 10px;
  }
`;

const Subtitle = styled.span`
  color: ${colors.navy};
  font-family: Libre Baskerville;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  margin-bottom: 7px;
  text-align: center;
`;

const Title = styled.span`
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
`;

const ListPost = ({ post }) => (
  <Content>
    <StyledLink to={post.path}>
      <Img src={post.listImage && post.listImage.publicURL} alt={post.listImageAlt} />
      <Overlay>
        <Subtitle>{post.category}</Subtitle>
        <Title>{post.title}</Title>
      </Overlay>
    </StyledLink>
  </Content>
);

ListPost.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    listImageAlt: PropTypes.string.isRequired,
    listImage: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ListPost;
