import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MenuBar from '../components/home/MenuBar';
import Breadcrumb from '../components/LandingPage/Breadcrumb';
import LaRubriqueListContent from '../components/LaRubrique/LaRubriqueListContent';
import LaRubriqueFooter from '../components/LaRubrique/LaRubriqueFooter';
import Footer from '../components/home/v3/Footer';
import Layout from '../layouts/index';
import { Container, Content } from '../components/home/homeStyledComponents';
import {
  margins, colors, mobileThresholdPixels, fontSizes, ButtonContainer,
} from '../components/home/v2/styledComponents';
import CTALink from '../components/CTALink';

const TitleContainer = styled.div`
  color: ${colors.navy};
  background-color: ${colors.lightGrey};
  padding: ${margins.xs} 0px ${margins.m};
  margin-bottom: ${margins.l};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: ${margins.xs} 0px ${margins.s};
  }
`;

const SubTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
  margin: 30px auto;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 80%;
  }
`;

const BreadcrumbContainer = styled.div`
  display: flex;
  margin-left: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    display: inherit;
    margin: auto;
  }
`;

const Title = styled.h1`
  color: ${colors.navy};
  text-align: center;
  font-size: ${fontSizes.x2l};
  margin: 0px auto;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.l};
  }
`;

const Span1 = styled.span`
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: ${margins.xs};
`;

const Span2 = styled(Span1)`font-weight: 400;`;

const ClothesListTemplate = ({ pageContext: { subCategory, category, categories, posts } }) => (
  <Layout
    routeSlug={(subCategory && subCategory.slug) ? `LaRubriqueCategory_${subCategory.slug}` : 'LaRubrique'}
  >
    <Container>
      <Content>
        <MenuBar />
        <TitleContainer>
          <BreadcrumbContainer>
            <Breadcrumb lightVersion itemListElement={subCategory.breadcrumb} />
          </BreadcrumbContainer>
          <Title>
            {(subCategory && subCategory.title) || 'Trouvez des inspirations pour votre dressing'}
          </Title>
          <SubTitleContainer>
            <Span1>Vous avez besoin d’une retouche ? </Span1>
            <Span2>
              Si je vous dis que nos couturiers modélistes se déplacent en plus à domicile de 7h à 22h 7j/7.
              Vous êtes bichonnés au prix de la retoucherie de quartier !
            </Span2>
            <ButtonContainer margin={'30px 0px 0px'}>
              <CTALink fromInteraction="LaRubriqueClothHeader" transparent linkState={subCategory.prefilledSteps} />
            </ButtonContainer>
          </SubTitleContainer>
        </TitleContainer>
        <LaRubriqueListContent
          posts={posts} categories={categories}
          category={category} subCategory={subCategory}
        />
        <LaRubriqueFooter category={category} prefilledSteps={subCategory.prefilledSteps} />
        <Footer />
      </Content>
    </Container>
  </Layout>
);


ClothesListTemplate.propTypes = {
  pageContext: PropTypes.shape({
    subCategory: PropTypes.shape({}),
    category: PropTypes.shape({}),
    categories: PropTypes.arrayOf(PropTypes.shape({})),
    posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default ClothesListTemplate;
